import { ServiceInfo } from "../models/ServiceInfo";
import { AreaInfo } from "../models/AreaInfo";
import { Languages, defaultLanguages } from "../models/Languages";
import { EnumLang } from "../enum/EnumLang";
import i18n from "../configs/i18n";

export const getNameServiceLanguage = (service: ServiceInfo): Languages => {
  const language = i18n.language as EnumLang;

  let responseLanguages: Languages = defaultLanguages;

  if (service.languages?.length > 0) {
    service.languages.forEach((serviceLang: Languages) => {
      if (serviceLang.culture === language && serviceLang.displayName !== "") {
        responseLanguages = serviceLang;
      }
    });
    if (responseLanguages.id === -1) {
      responseLanguages = service.languages[0];
    }
  }
  return responseLanguages;
};

export const getNameAreaLanguage = (service: AreaInfo): Languages => {
  const language = i18n.language as EnumLang;
  let responseLanguages: Languages = defaultLanguages;
  if (service.languages?.length > 0) {
    service.languages.forEach((serviceLang: Languages) => {
      if (serviceLang.culture === language && serviceLang.displayName !== "") {
        responseLanguages = serviceLang;
      }
    });
    if (responseLanguages.id === -1) {
      responseLanguages = service.languages[0];
    }
  }
  return responseLanguages;
};

export const getLanguageNavigator = (): EnumLang => {
  const langNavigator: string = navigator.language.substring(0, 2);
  if (
    langNavigator === EnumLang.ca ||
    langNavigator === EnumLang.es ||
    langNavigator === EnumLang.en ||
    langNavigator === EnumLang.oc
  ) {
    return EnumLang[langNavigator];
  } else {
    return EnumLang.ca;
  }
};

import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, Form, Message } from "semantic-ui-react";
import RichTextEditorField from "./RichTextEditorField";
import TextField from "./TextField";
import { getService, putService } from "../../../assets/api";
import { Service } from "../../../types/service";
import { AuthContext } from "../../../context/AuthContext";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

interface Props {
  organitzationURL: any;
  setOrganitzationURL: any;
  isRecording: any;
  allowDocumentation: any;
  updateAllowDocumentation: any;
  updateRecordingsForOrganization: any;
  organitzationURLInformers: any;
  setOrganitzationURLInformers: any;
  updateInformersNamesForService: any;
  updateType: any;
  showName: any;
}

const defaultsService: Service = {
  languages: [],
  code: "",
  areaCode: "",
  active: false,
  displayName: "",
  email: "",
  recordMeetingEnabled: true,
  uri: "",
  serviceType: null,
};

export default (props: Props) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(true);
  const [videoAtencioActivat, setVideoAtencioActivat] = useState(false); // Estado para controlar si la grabación de videoatenciones está activada o desactivada
  const [selectedServiceType, setSelectedServiceType] = useState<any>("C"); // Estado para almacenar el valor del motivo por el que se quiere grabar, por defecto 'Calidad del servicio'
  const [services, setServices] = useState<Service>(defaultsService);
  const [loading, isLoading] = useState<boolean>(true);
  const auth = useContext(AuthContext);
  const { serviceCode } = useParams<any>();
  const history = useHistory();

  useEffect(() => {
    // Al iniciar el componente, guardamos el valor inicial del motivo por el que se quiere grabar
    setSelectedServiceType(props.isRecording ? selectedServiceType : null);
    getServices();
  }, []);

  const getServices = async () => {
    if (loading) {
      const token = await auth.getTokenForScopes();

      await getService(serviceCode, token.backoffice)
        .then(async (response) => response.json())
        .then((response: Service) => {
          setServices(response);
          isLoading(false);
        })
        .catch((error) => {
          alert(error);
        });
    }
  };



  // Función para manejar el cambio en la selección del tipo de motivo por el que se quiere grabar
  const updateType = (event: React.SyntheticEvent, data: any) => {
    const currentServices = services;
    currentServices.serviceType = data.value || null;

    setServices({ ...currentServices })

    updateService();

  };


  const updateService = async () => {
    const token = await auth.getTokenForScopes();
    putService(services, token.backoffice)
      .then((response: Response) => {
        console.log("response", response)
        if (!response.ok)
          throw new Error(
            t("message.edit.service.error") + ` (${services.code})`
          );
        return response.json();
      })
      .then((response: Service) => {
        toast((
          t("message.edit.service.success") + ` (${services.code})`
        ), { type: "success" });
      })
      .catch((e) => {
        toast((e.message), { type: "error" });
      });
  }
 
  return (
    <div>
      <TextField
        title={t("detail.personalitzation.url")}
        value={props.organitzationURL}
        onChange={(smsWithLinktoEvent: any) =>
          props.setOrganitzationURL(smsWithLinktoEvent)
        }
        id="entityOrganitzationURL"
      />

      <TextField
        title={t("detail.personalitzation.url_informers")}
        value={props.organitzationURLInformers}
        onChange={(smsWithLinktoEvent: any) =>
          props.setOrganitzationURLInformers(smsWithLinktoEvent)
        }
        id="entityOrganitzationURL"
      />

      <Checkbox
        label={t("detail.personalitzation.informators_names")}
        defaultChecked={props.showName}
        onChange={props.updateInformersNamesForService}
      />

      {show && (
        // Checkbox para activar/desactivar la grabación de videoatenciones
        <Form.Field>
          <label style={{ marginBottom: "10px", marginTop: "10px" }}>
            {t("organitzation.personalitzation.recording")}
          </label>
          <Checkbox
            toggle
            checked={props.isRecording}
            onChange={props.updateRecordingsForOrganization}
          />
        </Form.Field>
      )}



      {props.isRecording && (
        // Selección del tipo de motivo por el que se quiere grabar, solo visible si la grabación de videoatenciones está activada
        <>
          <Form.Field>
            <label>{t("edit.service.type")}</label>
          </Form.Field>
          <div className="d-flex">
            <Form.Field>
              <Checkbox
                radio
                label={t("edit.service.information_service")}
                name="checkboxRadioGroup"
                value="C"
                checked={services.serviceType === "C" || services.serviceType === null}
                onChange={updateType}
              />
            </Form.Field>
            <Form.Field className="ml-3">
              <Checkbox
                radio
                label={t("edit.service.assisted_processing")}
                name="checkboxRadioGroup"
                value="T"
                checked={services.serviceType === "T"}
                onChange={updateType}
              />
            </Form.Field>
          </div>
        </>
      )}

      {show ? (
        <Form.Field>
          <label style={{ marginBottom: "10px", marginTop: "10px" }}>
            {t("organitzation.personalitzation.sending_previous")}
          </label>

          <Checkbox
            toggle
            defaultChecked={props.allowDocumentation}
            onChange={props.updateAllowDocumentation}
          />
        </Form.Field>
      ) : null}
    </div>
  );
};

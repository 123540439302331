
import { TFunction } from "react-i18next";

export const getReasonCodesMap = (t: TFunction): { [key: string]: string } => ({
    allRight: t("home.endModal.status_reason.reason1"),
    absence: t("home.endModal.status_reason.reason2"),
    skillsIssues: t("home.endModal.status_reason.reason3"),
    technicalIssues: t("home.endModal.status_reason.reason4"),
    finalizedBySystemAbsence: t("home.endModal.status_reason.reason5"),
    finalizedBySystem: t("home.endModal.status_reason.reason6"),
    other: t("home.endModal.status_reason.reason7")
  });
import React, { useContext, useEffect, useState } from 'react';
import { Area } from '../../types/area';
import Screen from './Screen';
import { AuthContext } from "../../context/AuthContext";
import { getAreaInfo } from '../../assets/api';
import { getNameAreaLanguage } from '../../util/languages';
import { AreaInfo } from '../../models/AreaInfo';
import { useTranslation } from 'react-i18next';

interface Props {
  header: string;
  areas: Area[];
  multiple?: boolean;
  onChange: (x: string) => void;
  classNameContainer?: string;
  styleContainer?: any;
}


const DropDownAreas = (props: Props) => {

  const [areaSelected, setAreaSelected] = useState<string>("");
  const [translatedAreas, settranslatedAreas] = useState<any[]>([]);
  const [originalAreas, setOriginalAreas] = useState<AreaInfo[]>([]);
  const authContext = useContext(AuthContext);
  const { i18n } = useTranslation();


  const initialAreas = props.areas
    .filter(({ active }) => active)
    .map(({ displayName, code }, index) => ({
      key: index,
      value: code,
      text: displayName,
    }));


  useEffect(() => {
    const fetchAreas = async () => {
      const { backoffice } = await authContext.getTokenForScopes();
      const areaPromises = props.areas.map(async (area) => {
        const response = await getAreaInfo(area.code, backoffice);
        if (response.ok) {
          const areaInfo = await response.json();
          return areaInfo;
        }
        return null;
      });

      const originalAreasArray = await Promise.all(areaPromises);
      const validAreas = originalAreasArray.filter((area) => area !== null) as AreaInfo[];
      if (validAreas.length > 0) {
        setOriginalAreas(validAreas);
      }
    };

    fetchAreas();
  }, [props.areas, authContext]);

  const translateAreas = () => {
    const translatedArray = originalAreas.map((area, index) => {
      const translatedArea = getNameAreaLanguage(area);
      return {
        key: index,
        value: area.code,
        text: translatedArea.displayName,
      };
    });
    settranslatedAreas(translatedArray);
  };

  useEffect(() => {
    if (originalAreas.length > 0) {
      translateAreas();
    }
  }, [originalAreas, i18n.language]);

  const onChange = (event: any, data: any) => {
    const area = data.value;
    setAreaSelected(area);
    props.onChange(area);
  };

  return (
    <Screen
      {...props}
      areas={translatedAreas.length > 0 ? translatedAreas : initialAreas}
      areaSelect={areaSelected}
      onChange={onChange}
      multiple={false}
    />
  );
};

export default DropDownAreas;
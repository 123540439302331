import { Area } from './../types/area';
import { AppThunk } from './../app/store';
import { Service } from "../types/service";
import { fetchMSGraph } from "../util/auth";
import { EnumHTTPMethod } from "../enum/EnumHTTPMethod";
import { Users } from "../types/area";
import { ExpressMeeting } from "../types/dashboard";
import { UserToAdd } from "../types/organization";
import { ServiceInfo, defaultServiceInfo } from "../models/ServiceInfo";
import { EventObject, defaultEventObject } from "../models/EventObject";
import { UserInfo } from "../models/User";
import { string } from "yup/lib/locale";
import moment from "moment";
import { async } from "@firebase/util";
import { GET_MY_AREAS } from './areas';
import {createPhonePrefixAdapter} from '../adapters/phones.adapter';
import flags from '../util/countries';
import { DocumentApiResponse, defaultDocumentApiResponse } from '../models/DocumentationObject';
import { ResponseType } from '@microsoft/microsoft-graph-client';

export async function fetchServices(area: string, token: string) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Areas/${area}/services`;

  const response = await fetchMSGraph(endpoint, token);
  return response;
}

export const fetchAreas = (token: string): AppThunk => async (dispatch) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/Areas`;

  const response = await fetchMSGraph(endpoint, token);
  if (!response.ok) throw new Error("Error fecthMyAreas");
  const data: Area[] = await response.json();
  const myAreas = data.filter(obj => !!obj.displayName && !!obj.code)
  dispatch(GET_MY_AREAS(myAreas));
}


export async function fetchMyAreasAdmin(token: string) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Areas/admin/myareas`;
  const response = await fetchMSGraph(endpoint, token);

  return response;
}

export async function fetchArea(areaId: string, token: string) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Areas/${areaId}`;

  const response = await fetchMSGraph(endpoint, token);
  return response;
}

export async function fetchAreaUsers(areaId: string, token: string) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Areas/${areaId}/users`;

  const response = await fetchMSGraph(endpoint, token);
  return response;
}

export async function fetchAllUsers(token: string) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Organizations/users?IsAreaAdmin=true`;

  const response = await fetchMSGraph(endpoint, token);
  return response;
}

export async function getOrganizations(token: string) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Organizations`;

  const response = await fetchMSGraph(endpoint, token);
  return response;
}

type LanguageType = {
  culture: string;
  displayName: string;
};
type OrganizationType = {
  email: string;
  phone: string;
  url: string;
  recordMeetingEnabled?: boolean;
  languages: LanguageType[];
  active: boolean;
  displayName: string;
  code: string;
  sendPreviousDocumentation?: boolean;
};
export async function addOrganization(
  organization: OrganizationType,
  token: string
) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Organizations`;
  const body = JSON.stringify(organization);

  const response = await fetchMSGraph(
    endpoint,
    token,
    EnumHTTPMethod.POST,
    body
  );

  return response;
}

export async function editOrganization(
  organizationCode: string,
  organization: OrganizationType,
  token: string
) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Organizations/${organizationCode}`;
  const body = JSON.stringify(organization);

  const response = await fetchMSGraph(
    endpoint,
    token,
    EnumHTTPMethod.PUT,
    body
  );

  return response;
}

export async function updateLicensesOrganization(body: any, token: string) {
  const endpoint = `${process.env.REACT_APP_MEETING_SERVICES}/ManageBackOfficeConfiguration/Licenses`;
  const bodyStringify = JSON.stringify(body);

  const response = await fetchMSGraph(
    endpoint,
    token,
    EnumHTTPMethod.PUT,
    bodyStringify
  );

  return response;
}

export async function createLicensesOrganization(body: any, token: string) {
  const endpoint = `${process.env.REACT_APP_MEETING_SERVICES}/ManageBackOfficeConfiguration/Licenses`;
  const bodyStringify = JSON.stringify(body);

  const response = await fetchMSGraph(
    endpoint,
    token,
    EnumHTTPMethod.POST,
    bodyStringify
  );

  return response;
}

export async function getLicensesOrganization(token: string) {
  const endpoint = `${process.env.REACT_APP_MEETING_SERVICES}/ManageBackOfficeConfiguration/Licenses`;

  const response = await fetchMSGraph(endpoint, token);

  return response;
}
export async function getLimitMeetingsOrganization(token: string) {
  const endpoint = `${process.env.REACT_APP_MEETING_SERVICES}/ManageBackOfficeConfiguration/DailyMeeting`;

  const response = await fetchMSGraph(endpoint, token);

  return response;
}

export async function updateLimitMeetingsOrganization(
  body: any,
  token: string
) {
  const endpoint = `${process.env.REACT_APP_MEETING_SERVICES}/ManageBackOfficeConfiguration/DailyMeeting`;
  const bodyStringify = JSON.stringify(body);
  const response = await fetchMSGraph(
    endpoint,
    token,
    EnumHTTPMethod.PUT,
    bodyStringify
  );

  return response;
}

export async function createLimitMeetingsOrganization(
  body: any,
  token: string
) {
  const endpoint = `${process.env.REACT_APP_MEETING_SERVICES}/ManageBackOfficeConfiguration/DailyMeeting`;
  const bodyStringify = JSON.stringify(body);

  const response = await fetchMSGraph(
    endpoint,
    token,
    EnumHTTPMethod.POST,
    bodyStringify
  );

  return response;
}

export async function postArea(area: any, token: string) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Areas/`;
  const body = JSON.stringify(area);

  const response = await fetchMSGraph(
    endpoint,
    token,
    EnumHTTPMethod.POST,
    body
  );

  return response;
}

export async function addUserToArea(
  areaCode: string,
  userCode: Users,
  token: string
) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Areas/${areaCode}/users/${userCode}`;

  const response = await fetchMSGraph(endpoint, token, EnumHTTPMethod.PUT);
  return response;
}

export async function createExpressMeeting(
  expressMeeting: ExpressMeeting,
  token: string
) {
  const url = `${process.env.REACT_APP_API_URL}/Events`;
  const body = JSON.stringify(expressMeeting);
  const response = await fetchMSGraph(url, token, EnumHTTPMethod.POST, body);
  return response;
}

export async function isZipCode(
  zipCode: string,
  token: string
) {
  const url = `${process.env.REACT_APP_CO2_API_URL}/Municipio/${zipCode}`;
  const response = await fetchMSGraph(url, token);
  return response;
}


export async function deleteUserFromArea(
  areaId: string,
  user: string,
  token: string
) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Areas/${areaId}/users/${user}`;

  const response = await fetchMSGraph(endpoint, token, EnumHTTPMethod.DELETE);

  return response;
}

export async function addAdminToArea(
  areaCode: string,
  userCode: string,
  token: string
) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Areas/${areaCode}/administrators/${userCode}`;
  const response = await fetchMSGraph(endpoint, token, EnumHTTPMethod.PUT);
  return response;
}

export async function removeAreaAdmin(
  areaId: string,
  code: any,
  token: string
) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Areas/${areaId}/administrators/${code}`;

  const response = await fetchMSGraph(endpoint, token, EnumHTTPMethod.DELETE);

  return response;
}
export async function fetchAreaAdmins(areaId: string, token: string) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Areas/${areaId}/administrators`;

  const response = await fetchMSGraph(endpoint, token);
  return response;
}

export async function addServiceManagerToArea(
  areaCode: string,
  userCode: string,
  token: string
) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Areas/${areaCode}/servicesupervisor/${userCode}`;
  const response = await fetchMSGraph(endpoint, token, EnumHTTPMethod.PUT);
  return response;
}

export async function removeAreaServiceManager(
  areaId: string,
  code: any,
  token: string
) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Areas/${areaId}/servicesupervisor/${code}`;
  const response = await fetchMSGraph(endpoint, token, EnumHTTPMethod.DELETE);
  return response;
}

export async function fetchAreaServiceManager(areaId: string, token: string) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Areas/${areaId}/servicesupervisor`;
  const response = await fetchMSGraph(endpoint, token);
  return response;
}

export async function deleteArea(code: string, token: string) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Areas/${code}`;

  const response = await fetchMSGraph(endpoint, token, EnumHTTPMethod.DELETE);

  return response;
}

export async function putArea(state: any, token: string) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Areas/${state.code}`;

  const response = await fetchMSGraph(
    endpoint,
    token,
    EnumHTTPMethod.PUT,
    JSON.stringify(state)
  );

  return response;
}

export async function putAreaPreferences(areaCode: any, body: any, token: string) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Areas/${areaCode}/preferences`;

  const response = await fetchMSGraph(
    endpoint,
    token,
    EnumHTTPMethod.PUT,
    JSON.stringify(body)
  );

  return response;
}

export async function getServicePreferences(serviceCode: any, token: string) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Services/${serviceCode}/preferences`;

  const response = await fetchMSGraph(endpoint, token);

  return response;
}

export async function putServicePreferences(
  serviceCode: any,
  body: any,
  token: string
) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Services/${serviceCode}/preferences`;

  const response = await fetchMSGraph(
    endpoint,
    token,
    EnumHTTPMethod.PUT,
    JSON.stringify({ ...body, code: 0 })
  );

  return response;
}

export async function fetchEvents() {
  const date = "2020-12-18";

  const endpointa = `${process.env.REACT_APP_API_URL}/Events/service/PA06/day/${date}`;
  const res = await fetch(endpointa);

  const data = await res.json();
  if (!data) {
    throw new Error(data.message);
  }
  return data;
}

export async function getQueueStatus(
  serviceId: string,
  area: string,
  token: string
) {
  const endpoint = `${process.env.REACT_APP_MEETING_SERVICES}/GetMeetingsQueueStatus/${area}/${serviceId}`;
  const response = await fetchMSGraph(endpoint, token);

  const { Value } = await response.json();
  return Value;
}

export async function getServantJWT(token: string) {
  const endpoint = `${process.env.REACT_APP_MEETING_SERVICES}/AuthenticateMeetingServant`;

  const response = await fetchMSGraph(endpoint, token);

  return response;
}

export async function getServantJWTwithLicence(
  organitzation: string,
  meetingId: string = "*",
  token: string
) {
  const endpoint = `${process.env.REACT_APP_MEETING_SERVICES}/V2/AuthenticateMeetingServant/${organitzation}/${meetingId}`;

  const response = await fetchMSGraph(endpoint, token);

  return response;
}

export async function fetchCreateService(service: Service, token: string) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Services`;
  const body = JSON.stringify(service);

  const response = await fetchMSGraph(
    endpoint,
    token,
    EnumHTTPMethod.POST,
    body
  );

  return response;
}

export async function addUser(user: UserToAdd, token: string) {
  const endpoint = `${process.env.REACT_APP_API_URL}/User`;
  const body = JSON.stringify(user);

  const response = await fetchMSGraph(
    endpoint,
    token,
    EnumHTTPMethod.POST,
    body
  );

  return response;
}

export async function modifyUser(code: string, user: UserToAdd, token: string) {
  const endpoint = `${process.env.REACT_APP_API_URL}/user/${code}`;
  const body = JSON.stringify(user);
  console.log("USER", user);

  const response = await fetchMSGraph(
    endpoint,
    token,
    EnumHTTPMethod.PUT,
    body
  );

  return response;
}

export async function deleteUser(code: string, token: string) {
  const endpoint = `${process.env.REACT_APP_API_URL}/user/${code}`;

  const response = await fetchMSGraph(endpoint, token, EnumHTTPMethod.DELETE);

  return response;
}

export async function fetchRelationshipServiceWithArea(
  area: string,
  service: string,
  token: string
) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Areas/${area}/services`;
  const body = JSON.stringify({ code: service });

  const response = await fetchMSGraph(
    endpoint,
    token,
    EnumHTTPMethod.POST,
    body
  );

  return response;
}

export async function getService(code: string, token: string) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Services/${code}`;
  const response = await fetchMSGraph(endpoint, token);
  return response;
}

export async function getUsersArea(userCode: string, token: string) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Areas/user/${userCode}`;

  const response = await fetchMSGraph(endpoint, token);

  return response;
}



export async function getAreasByOrganization(
  organization: string,
  token: string
) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Organizations/${organization}`;

  const response = await fetchMSGraph(endpoint, token);

  return response;
}

export async function getAdminOrganization(token: string) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Organizations/admin/myorganization`;

  const response = await fetchMSGraph(endpoint, token);

  return response;
}

export async function addAdminOrganization(
  organizationCode: string,
  body: any,
  token: string
) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Organizations/${organizationCode}/administrators`;
  const response = await fetchMSGraph(
    endpoint,
    token,
    EnumHTTPMethod.POST,
    JSON.stringify(body)
  );
  return response;
}

export async function removeOrganizationAdmin(
  organizationCode: string,
  code: any,
  token: string
) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Organizations/${organizationCode}/administrators/${code}`;

  const response = await fetchMSGraph(endpoint, token, EnumHTTPMethod.DELETE);

  return response;
}

export async function  addOrganizationConsultor(
  organizationCode: string,
  body: any,
  token: string
) {
  const endpoint = `${process.env.REACT_APP_API_URL}/OrganizationsUsers/${body}/organization/${organizationCode}`;
  const response = await fetchMSGraph(endpoint,
    token,
    EnumHTTPMethod.POST,
    JSON.stringify(body)
    );

  return response;
}

export async function  removeOrganizationConsultor(
  organizationCode: string,
  code: any,
  token: string
) {
  const endpoint = `${process.env.REACT_APP_API_URL}/OrganizationsUsers/${code}/organization/${organizationCode}`;
  const response = await fetchMSGraph(endpoint, token, EnumHTTPMethod.DELETE);

  return response;
}

export async function getUserServices(userCode: string, token: string) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Services/user/${userCode}`;

  const response = await fetchMSGraph(endpoint, token);

  return response;
}

export async function deleteService(code: string, token: string) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Services/${code}`;

  const response = await fetchMSGraph(endpoint, token, EnumHTTPMethod.DELETE);

  return response;
}

export async function putService(body: Service, token: string) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Services/${body.code}`;

  const response = await fetchMSGraph(
    endpoint,
    token,
    EnumHTTPMethod.PUT,
    JSON.stringify(body)
  );

  return response;
}

export async function getMeetingFromQueue(
  meetingType: string,
  meetingArea: string,
  servantId: string,
  meeting: string,
  token: string
) {
  const endpoint = `${process.env.REACT_APP_MEETING_SERVICES}/GetMeetingFromQueue/${meeting}`;
  const body = JSON.stringify({
    ServiceCode: meetingType,
    MeetingArea: meetingArea,
    servantId: servantId
  });

  const response = await fetchMSGraph(
    endpoint,
    token,
    EnumHTTPMethod.POST,
    body
  );

  return response;
}

export async function getNextQueue(
  serviceCode: string,
  area: string,
  servantId: string,
  token: any,
  GetQueuesWithoutServantId: boolean = false
) {
  const endpoint = `${process.env.REACT_APP_MEETING_SERVICES}/GetNextMeeting`;
  const body = JSON.stringify({
    ServiceCode: serviceCode,
    ServantID: servantId,
    MeetingArea: area,
    GetQueuesWithoutServantId
  });
  
  try{
      const response = await fetchMSGraph(
        endpoint,
        token,
        EnumHTTPMethod.POST,
        body
      );
      
      const { Value} = await response.json();
      return Value;
  }catch(error){
    console.log("Meetings assigned API", error);
  }
    
  
}

export async function endMeeting(
  meetingId: string,
  reason: string,
  token: string
) {
  const endpoint = `${process.env.REACT_APP_MEETING_SERVICES}/NotifyMeetingStatus`;

  const body = JSON.stringify({
    MeetingEvent: "FinalizedMeeting",
    MeetingId: meetingId,
    Reason: reason,
  });

  return await fetchMSGraph(endpoint, token, EnumHTTPMethod.POST, body);
}

export async function getUserDetails(eventId: string, token: string) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Events/${eventId}`;

  const response = await fetchMSGraph(endpoint, token);

  return response;
}

export async function createExpressMeetingQueue(
  meetingArea: string,
  meetingId: string,
  servantId: string,
  jwt: string
) {
  console.info("[INFO] - Data", meetingArea, meetingId, servantId, jwt);

  const endpoint = `${process.env.REACT_APP_MEETING_SERVICES}/AssignMeeting`;

  const body = JSON.stringify({
    meetingArea: meetingArea,
    meetingID: meetingId,
    servantIdToAssignMeeting: servantId,
    isExpressMeeting: true,
  });

  return await fetchMSGraph(endpoint, jwt, EnumHTTPMethod.POST, body);
}

export async function assignMeetingQueue(
  meetingArea: string,
  meetingId: string,
  servantId: string,
  jwt: string
) {
  const endpoint = `${process.env.REACT_APP_MEETING_SERVICES}/AssignMeeting`;
  
  const body = JSON.stringify({
    meetingArea: meetingArea,
    meetingID: meetingId,
    servantIdToAssignMeeting: servantId,
    isExpressMeeting: false,
  });


  return await fetchMSGraph(endpoint, jwt, EnumHTTPMethod.POST, body);
}

export async function discardMeeting(
  meetingID: string,
  area: string,
  servantId: string,
  token: string
) {
  const endpoint = `${process.env.REACT_APP_MEETING_SERVICES}/DiscardMeeting`;

  const item = Object.keys(localStorage).filter(
    (v) => v.startsWith("msal.") && v.endsWith("idtoken")
  );
  //const token = localStorage.getItem(item[0]) || '';

  const body = JSON.stringify({
    meetingID: meetingID,
    servantID: servantId,
    ServantArea: area,
  });

  return await fetchMSGraph(endpoint, token, EnumHTTPMethod.POST, body);
}

export async function addAttendeeToMeeting(
  meetingID: string,
  name: string,
  email: string,
  phone: string,
  token: string
) {
  const endpoint = `${process.env.REACT_APP_MEETING_SERVICES}/AddMeetingAttendee/${meetingID}`;

  const item = Object.keys(localStorage).filter(
    (v) => v.startsWith("msal.") && v.endsWith("idtoken")
  );

  const body = JSON.stringify({
    culture: "Es-ca",
    fullName: name,
    email: email,
    phone: phone,
  });

  return await fetchMSGraph(endpoint, token, EnumHTTPMethod.POST, body);
}

export async function deleteMeetingAtendeeFunction(
  meetingID: string,
  name: string,
  email: string,
  phone: string,
  token: string
) {
  const endpoint = `${process.env.REACT_APP_MEETING_SERVICES}/DeleteMeetingAtendee/${meetingID}`;

  const body = JSON.stringify({
    culture: "Es-ca",
    fullName: name,
    email: email,
    phone: phone
  });

  return await fetchMSGraph(endpoint, token, EnumHTTPMethod.DELETE, body);
}

export async function getMyAreas(userCode: string, token: string) {
  const url = `${process.env.REACT_APP_API_URL}/Areas/user/${userCode}`;
  return await fetchMSGraph(url, token);
}

export async function serviceGetInfo(codeService: string, token: string) {
  console.log("QueueCurrentEvent", codeService);
  const url = `${process.env.REACT_APP_API_URL}/Services/${codeService}`;
  let data: ServiceInfo = defaultServiceInfo;
  try {
    const response: Response = await fetchMSGraph(url, token);
    if (!response.ok) throw new Error("Bad response");
    data = await response.json();
  } catch (error) {
    console.warn(`Error API Services - Message ${error}`);
  }
  return data;
}
export async function eventsGetInfo(codeEvent: string, token: string) {
  const url = `${process.env.REACT_APP_API_URL}/Events/${codeEvent}`;
  let data: EventObject = defaultEventObject;
  try {
    const response: Response = await fetchMSGraph(url, token);
    if (!response.ok) throw new Error("Bad Response");
    data = await response.json();
  } catch (error) {
    console.warn(`Error API Events - Message ${error}`);
  }
  return data;
}
export async function getMeetingDocumentation(meetingId: string, token: string) {
  const url = `${process.env.REACT_APP_API_URL}/Documentation/GetDocumentation/`+meetingId;
  let data: DocumentApiResponse = defaultDocumentApiResponse;
  try {
    const response: Response = await fetchMSGraph(url, token);
    console.log("response", response)
    if (!response.ok) throw new Error("Bad Response");
    data = await response.json();

  } catch (error) {
    console.warn(`Error API Events - Message ${error}`);
  }
  return data;
}

export async function getMeetingDocument(url: string, token: string) {
  let data: Response=new Response();
  try {
    const response: Response = await fetchMSGraph(url, token);
    if (!response.ok) throw new Error("Bad Response");
    data = await response;
  } catch (error) {
    console.warn(`Error API Events - Message ${error}`);
  }
  return data;
}

export async function getUsersOrganization(
  root: boolean,
  tenant: boolean,
  organization: boolean,
  area: boolean,
  token: string
) {
  const url = `${process.env.REACT_APP_API_URL}/Organizations/users?IsRoot=${root}&IsTenantAdmin=${tenant}&IsOrganizationAdmin=${organization}&IsAreaAdmin=${area}`;
  const data: UserInfo[] = [];
  try {
    const response: Response = await fetchMSGraph(url, token);
    if (!response.ok) throw new Error("Bad Response");
    const responseData: UserInfo[] = await response.json();
    responseData.forEach((user: UserInfo) => {
      data.push(user);
    });
  } catch (error) {
    console.warn(
      `Error API Organization "getUsersOrganization" - Message ${error}`
    );
  }
  return data;
}

export async function getServiceInfo(codeService: string, token: string) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Services/${codeService}`;
  const response = await fetchMSGraph(endpoint, token);
  return response;
}

export async function getAreaInfo(code: string, token: string) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Areas/${code}`;
  const response = await fetchMSGraph(endpoint, token);
  return response;
}

export async function getAreaPreferences(code: string, token: string) {
  const endpoint = `${process.env.REACT_APP_API_URL}/Areas/${code}/preferences`;
  const response = await fetchMSGraph(endpoint, token);
  return response;
}

type RecordingDetailsForm = {
  dateStart: string;
  dateEnd: string;
  area: string;
  service: string;
  organizations?: string[];
};
export async function getRecordingDetails(
  form: RecordingDetailsForm,
  maxItemsPerRow: number,
  continuationToken: any,
  token: string
) {
  interface RecordingBodyRequest {
    start: string;
    end: string;
    area: string;
    service: string;
    maxItems: number;
    continuationToken?: string;
    organizations?: string[];
  }
  const { dateStart: start, dateEnd: end, area, service, organizations } = form;
  let body: RecordingBodyRequest = {
    start,
    end,
    area,
    service,
    maxItems: maxItemsPerRow,
    ...(continuationToken ? { continuationToken: continuationToken } : {}),
    ...(organizations ? { organizations: organizations } : {}),
  };
  const endpoint = `${process.env.REACT_APP_API_URL}/Organizations/statistics${organizations ? `/crossreader` : ``}`;
  const response = await fetchMSGraph(
    endpoint,
    token,
    EnumHTTPMethod.POST,
    JSON.stringify(body)
  );

  return response;
}

export async function downloadRecordingsCSV(form: any, token: string) {
  console.log("Form", form);

  const formatedForm = {
    ...form,
    start: form.dateStart,
    end: form.dateEnd,
  };
  let response;
  if (form.organizations) {
    const endpoint = `${process.env.REACT_APP_API_URL}/Organizations/crossreader/statisticsJSON`;
    response = await fetchMSGraph(
      endpoint,
      token,
      EnumHTTPMethod.PUT,
      JSON.stringify(formatedForm)
    );
  } else {
    let endpoint = `${process.env.REACT_APP_API_URL}/Organizations/statisticsJSON?Start=${formatedForm.start}&End=${formatedForm.end}`;
    if(form.area != null) endpoint = endpoint +`&AreaCode=${form.area}`;
    if(form.service != null) endpoint = endpoint + `&Service=${form.service}`;

    response = await fetchMSGraph(endpoint, token);
  }
  return response;
}

export async function exportCSV(form: any, token: any, servicesWithMeetings: any[]) {
  let startDate = moment(form.dateStart).format("YYYYMMDD");
  let endDate = moment(form.dateEnd).format("YYYYMMDD");
  let response = await Promise.all(servicesWithMeetings.map(async (service: any) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/Events/filtertofile/${startDate}/${endDate}/${service}`;
    let responseJSON = await fetchMSGraph(endpoint, token)
      .then(async (response) => await response.json())

    return responseJSON;
  }))
  return response;
}

export function getPhonePrefixes () {
  const lang = (window.navigator.language).split("-");
  const language = lang[0];
  return flags.map(entry => createPhonePrefixAdapter(entry.code, entry.translations[language], entry.dial_code)).sort((first_entry, last_entry) => (first_entry.text > last_entry.text) ? 1 : -1);
}

function saveAs(blob: Blob, filename: string) {
  throw new Error('Function not implemented.');
}

import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchGetAreasServicesApi,
  selectMyAreas,
  selectServices,
} from "../../assets/areas";
import { AuthContext } from "../../context/AuthContext";
import { Service } from "../../models/Service";
import Screen from "./Screen";
import { ServiceInfo } from "../../models/ServiceInfo";
import { getNameServiceLanguage } from "../../util/languages";
import { getService } from "../../assets/api";


import { useTranslation } from "react-i18next";


interface Props {
  header: string;
  services: Service[];
  onChange: (x: string) => void;
  classNameContainer?: string;
  styleContainer?: any;
  multiple?: boolean;
}

const DropDownServices = (props: Props) => {

  const [serviceSelect, setServiceSelect] = useState<string>("");
  const [originalServices, setOriginalServices] = useState<ServiceInfo[]>([]);
  const [translatedServices, setTranslatedServices] = useState<{ key: number; value: string; text: string }[]>([]);
  const authContext = useContext(AuthContext);
  const { i18n } = useTranslation();

  const initialServices = props.services
    .filter(({ active }) => active)
    .map(({ displayName, code }, index) => ({
      key: index,
      value: code,
      text: displayName,
    }));


  useEffect(() => {
    const fetchAndTranslateServices = async () => {
      const { backoffice } = await authContext.getTokenForScopes();
      const servicePromises = props.services.map(async (service) => {
        const response = await getService(service.code, backoffice);
        if (response.ok) {
          const serviceInfo: ServiceInfo = await response.json();
          return serviceInfo;
        }
        return null;
      });

      const originalServicesArray = await Promise.all(servicePromises);
      const validServices = originalServicesArray.filter((service) => service !== null) as ServiceInfo[];
      if (validServices.length > 0) {
        setOriginalServices(validServices);
      }
    };

    fetchAndTranslateServices();
  }, [props.services, authContext]);

  const translateServices = () => {
    const translatedArray = originalServices.map((service, index) => {
      const translatedService = getNameServiceLanguage(service);
      return {
        key: index,
        value: service.code,
        text: translatedService.displayName,
      };
    });
    setTranslatedServices(translatedArray);
  };

  useEffect(() => {
    if (originalServices.length > 0) {
      translateServices();
    }
  }, [originalServices, i18n.language]);

  const onChange = (event: any, data: any) => {
    const service = data.value;
    setServiceSelect(service);
    props.onChange(service);
  };

  return (
    <Screen
      {...props}
      services={translatedServices.length > 0 ? translatedServices : initialServices}
      onChange={onChange}
      serviceSelect={serviceSelect}
      multiple={false}
    />
  );
};
export default DropDownServices;